<template>
  <div>
    <!-- search input -->
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="card-body">
          <h2 class="text-primary flex align-items-center">
            <feather-icon
              icon="UserIcon"
              size="24"
              class="block mr-25 mt-n25"
            />
            User Finder
          </h2>
          <b-card-text class="mb-2">
            <span>Please input the user's exact username</span>
          </b-card-text>

          <!-- form -->
          <b-form
            class="kb-search-input"
            @submit.prevent="getData"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="keyword"
                placeholder="Search username"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  type="submit"
                >
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->

    <section>

      <!-- content -->
      <b-row
        class="justify-content-md-center"
      >
        <b-col
          cols="12"
          md="6"
        >
          <div v-if="!loading && resultLength">
            <b-card
              v-for="(item, index) in results"
              :key="`list-item-${index}`"
              class="mb-4"
              no-body
            >
              <b-card-title class="mb-0 mx-1 mt-1 d-flex align-items-end">
                <feather-icon
                  icon="UserIcon"
                  size="20"
                  class="mr-50"
                />
                <router-link :to="`/user/${item[0].userID}`">{{ item[0].activeNickname }}
                  <feather-icon
                    icon="ExternalLinkIcon"
                    class="ml-25"
                  />
                </router-link>
              </b-card-title>

              <hr class="mb-0">

              <b-card-body class="card-body">
                <app-timeline>
                  <app-timeline-item
                    v-for="(itemList, indexList) in getDataSorted(item)"
                    :key="`list-item-list-${indexList}`"
                    :title="`${itemList.newNickname} ${itemList.is_current ? `(Current)`:''}`"
                    :subtitle="`Changed from <strong>${itemList.oldNickname}</strong>.`"
                    :time="getTime(itemList.createdAt)"
                    variant="primary"
                  />
                </app-timeline>
              </b-card-body>
            </b-card>
          </div>

          <div
            v-if="loading"
            class="text-center"
          >
            <b-spinner
              label="Loading..."
              variant="primary"
            />
          </div>

          <div
            v-if="!loading && resultLength === 0 && keywordUse && isSubmited"
            class="text-center"
          >
            <img
              :src="require('@/assets/images/pages/api.svg')"
              alt="Login V2"
              class="mb-4"
              width="70%"
            >
            <h4 class="mb-1">
              <strong>No Results found</strong>
            </h4>
            <div>
              We cannot find any result for <strong>"{{ keywordUse }}"</strong>.
            </div>
          </div>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BCardTitle, BSpinner, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BSpinner,
    BInputGroupAppend,
    BButton,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      keyword: '',
      keywordUse: '',
      kb: [],
      data: [],
      isSubmited: false,
    }
  },
  computed: {
    results() {
      return this.$store.state.user.usernameTrackerList.reduce((result, item) => {
        const { userID } = item
        if (!result[userID]) {
          // eslint-disable-next-line no-param-reassign
          result[userID] = []
        }
        result[userID].push(item)
        return result
      }, {})
    },
    resultLength() {
      return this.$store.state.user.usernameTrackerList.length
    },
    loading() {
      return this.$store.state.user.loadingUsernameTracker
    },
  },
  mounted() {
    this.$store.commit('user/SET_USERNAME_TRACKER_LIST', [])
  },
  methods: {
    getData() {
      this.$store.dispatch('user/getUsernameTrackerList', this.keyword)
      this.isSubmited = true
      this.keywordUse = this.keyword
    },
    getTime(val) {
      return moment(val).utc().format('DD/MM/YYYY HH:mm')
    },
    getDataSorted(data) {
      return data.map(el => ({
        ...el,
        is_current: el.newNickname === el.activeNickname,
      })).sort((a, b) => {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        return dateB - dateA
      })
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/pages/page-knowledge-base.scss';
  </style>
